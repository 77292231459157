import React from 'react';
import './SideWindowStyles.css';

const SideWindow = ({ url, isOpen, onClose }) => {
  return (
    <div className={`side-window ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={onClose}>
        Close
      </button>
      <iframe 
        src={url} 
        title="Side Window Content"
        className="side-window-iframe"
      />
    </div>
  );
};

export default SideWindow;
