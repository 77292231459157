import React, { useState } from 'react';
import { AudioMutedOutlined, AudioOutlined, EyeOutlined, EyeInvisibleOutlined, CloseOutlined, LikeOutlined, DownOutlined, PushpinOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import './ParticipantList.css';

const ParticipantList = ({ 
  api,
  participants,
  setIsParticipantListOpen,
  localParticipantId,
  setLocalApplause,
  setApplaudParticipantId
}) => {

  const [activeButton, setActiveButton] = useState(null);

  // Trigger animation and remove after a short delay
  const triggerAnimation = (id, buttonType) => {
    setActiveButton({ id, buttonType });
    setTimeout(() => setActiveButton(null), 500);
  };
  
  /* const handleAudioToggle = (id, isMuted) => {
    const action = isMuted ? 'joinAudio' : 'leaveAudio';
    api.executeCommand('sendEndpointTextMessage', id, action);
    triggerAnimation(id, 'show');
  }; */

  const handleSelfViewToggle = (id, toHide) => {
    const actionText = toHide ? "hideSelfView" : "showSelfView";
    const actionButton = toHide ? "hideSelfViewButton" : "showSelfViewButton";
    api.executeCommand('sendEndpointTextMessage', id, actionText);
    triggerAnimation(id, actionButton);
  };
  const handleUserMute = (id, muteStatus) => {
    const actionText = muteStatus ? "muteUser" : "unmuteUser";
    const actionButton = muteStatus ? "muteButton" : "unmuteButton";
    api.executeCommand('sendEndpointTextMessage', id, actionText);
    triggerAnimation(id, actionButton);
  };
  const handleApplaud = (id, type) => {
    const actionText = `applaud-${type}-${id}`;
    const actionButton = `applaud${type}Button`;
    api.executeCommand('sendEndpointTextMessage', id, actionText);
    setLocalApplause(type);
    setApplaudParticipantId(id);
    triggerAnimation(id, actionButton);
  };

  const applaudTypes = [
    "clap", "stars"]

  return (
    <div className="modalOverlay">
      <div className="participantListWrapper">
        <CloseOutlined 
          className="actionButton closeButton"
          onClick={() => setIsParticipantListOpen(false)}
        />
        <h4>Participant List</h4>
        
        <table className="participantTable">
          <thead>
            <tr>
              <th>Participant</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant) => (
              <tr key={participant.id}>
                <td>{participant.displayName} {localParticipantId == participant.id ? `(You)` : null}</td>
                <td>
                {localParticipantId != participant.id ?
                <>
                <div className="actionContainer">
                  <div className="actionGroup">
                    <Tooltip title="Show Self View">
                      <div 
                        className={`actionButton showSelfViewButton ${activeButton?.id === participant.id && activeButton.buttonType === 'showSelfViewButton' ? 'zoom' : ''}`}
                        onClick={() => handleSelfViewToggle(participant.id, false)}
                      >
                        <EyeOutlined />
                      </div>
                    </Tooltip>
                    <Tooltip title="Hide Self View">
                      <div 
                        className={`actionButton hideSelfViewButton ${activeButton?.id === participant.id && activeButton.buttonType === 'hideSelfViewButton' ? 'zoom' : ''}`}
                        onClick={() => handleSelfViewToggle(participant.id, true)}
                        >
                        <EyeInvisibleOutlined />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="verticalSeparator"></div>
                  <div className="actionGroup">
                    <Tooltip title="Mute">
                      <div 
                        className={`actionButton muteButton ${activeButton?.id === participant.id && activeButton.buttonType === 'muteButton' ? 'zoom' : ''}`}
                        onClick={() => handleUserMute(participant.id, true)}
                        >
                        <AudioMutedOutlined />
                      </div>
                    </Tooltip>
                    <Tooltip title="Unmute">
                      <div 
                        className={`actionButton unmuteButton ${activeButton?.id === participant.id && activeButton.buttonType === 'unmuteButton' ? 'zoom' : ''}`}
                        onClick={() => handleUserMute(participant.id, false)}
                        >
                        <AudioOutlined />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="verticalSeparator"></div>
                  <div className="actionGroup">
                    <Tooltip title="Applaud">
                      <div 
                        className={`actionButton applaudclapButton ${activeButton?.id === participant.id && activeButton.buttonType === 'applaudclapButton' ? 'zoom' : ''}`}
                        onClick={() => handleApplaud(participant.id, "clap")}
                        >
                        👏
                      </div>
                    </Tooltip>
                  </div>
                  <div className="actionGroup">
                    <Tooltip title="Stars">
                      <div 
                        className={`actionButton applaudstarsButton ${activeButton?.id === participant.id && activeButton.buttonType === 'applaudstarsButton' ? 'zoom' : ''}`}
                        onClick={() => handleApplaud(participant.id, "stars")}
                        >
                        ⭐
                      </div>
                    </Tooltip>
                  </div>




                  {/* Applaud Dropdown 
                  <div className="actionGroup">
                    <Tooltip title="Applaud">
                      <Dropdown menu={applaudMenu} trigger={['click']}>
                        <div className="actionButton applaudButton">
                          <span>Applaud</span> <DownOutlined />
                        </div>
                      </Dropdown>
                    </Tooltip>
                    <Tooltip title="Push Applaud">
                      <div
                        className="actionButton applaudPushButton"
                        onClick={() => pushApplaud(participant.id)}
                      >
                        <PushpinOutlined />
                      </div>
                    </Tooltip>
                  </div>*/}
                </div>
                  </> : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ParticipantList;
